import React from "react"
import { get } from "lodash"
import { graphql } from "gatsby"
import LandingPageJumbotron from "../components/LandingPageJumbotron/LandingPageJumbotron.js"
import LandingPageAboutSection from "../components/LandingPageAboutSection/LandingPageAboutSection.js"
import ContactSection from "../components/LandingPageContactSection/LandingPageContactSection.js"
import NavOverlay from "../components/NavOverlay/NavOverlay.js"

const Landing = ({data}) => {
  const backgroundImage = get(data, 'landingPage.forestImage.sourceUrl');
  const schemeButton = get(data, 'landingPage.buttonLabel');
  const schemeButtonUrl = get(data, 'landingPage.buttonLink');
  const landingPageJumbotronTitleBeginning = get(data, 'landingPage.jumbotronPageTitlePart1');
  const landingPageJumbotronTitleEnding = [
    get(data, 'landingPage.jumbotronPageTitlePart2'), 
    get(data, 'landingPage.jumbotronPageTitlePart3'),
    get(data, 'landingPage.jumbotronPageTitlePart4')
  ];
  
  const aboutSectionTitle = get(data, 'landingPage.aboutProjectTitle');
  const aboutSection = get(data, 'landingPage.aboutProject');
  const aboutSectionLogo = get(data, 'landingPage.aboutProjectLogo');
  const aboutSectionImage = get(data, 'landingPage.aboutProjectImage');

  const contactBackgroundImage = get(data, 'landingPage.forestImage2.sourceUrl');
  const contactTitle = get(data, 'landingPage.contactTitle');
  const contactInfoParagraph = get(data, 'landingPage.contact');
  const contactEmail = get(data, 'landingPage.email');
  const contactPhone = get(data, 'landingPage.phone');
  const contactAddress = get(data, 'landingPage.address');

  return(
    <div className="page">
      <LandingPageJumbotron 
        backgroundImage={backgroundImage} 
        schemeButton={schemeButton}
        schemeButtonUrl={schemeButtonUrl}
        landingPageJumbotronTitleBeginning={landingPageJumbotronTitleBeginning} 
        landingPageJumbotronTitleEnding={landingPageJumbotronTitleEnding}
      />

      <LandingPageAboutSection 
        aboutSectionTitle={aboutSectionTitle} 
        aboutSection={aboutSection} 
        aboutSectionLogo={aboutSectionLogo} 
        aboutSectionImage={aboutSectionImage} 
      />

      <ContactSection 
        contactBackgroundImage={contactBackgroundImage} 
        contactTitle={contactTitle} 
        contactInfoParagraph={contactInfoParagraph} 
        contactEmail={contactEmail} 
        contactPhone={contactPhone} 
        contactAddress={contactAddress}
      />
    </div>
  )
}

const IndexPage = (props) => {
  const currentPage = get(props,'data.wpgraphql.page', {})
  return(
    <>
      <NavOverlay
        menuData={get(props,'data.wpgraphql.menu')}
        isInfoPage
        indexPageMenuData={get(props, 'data.wpgraphql.altMenu')}
      />
      <Landing data={currentPage} />
    </>
  )
}

export default IndexPage

export const query = graphql` 
  query IndexPageQuery {
    wpgraphql {
      page(id: "cG9zdDoy") {
        landingPage {
          buttonLabel,
          buttonLink,
          jumbotronPageTitlePart1
          jumbotronPageTitlePart2
          jumbotronPageTitlePart3
          jumbotronPageTitlePart4
          aboutProjectTitle
          aboutProject
          aboutProjectLogo {
            id
            sourceUrl: sourceUrl(size: LARGE)
          }
          aboutProjectImage {
            id
            sourceUrl: sourceUrl(size: LARGE)
          }
          contact 
          contactTitle
          phone
          email
          address
          forestImage {
            id
            sourceUrl: sourceUrl(size: LARGE)
          }
          forestImage2 {
            id
            sourceUrl: sourceUrl(size: _1536X1536)
          }
        }
        link
      }
      menu(id: "bmF2X21lbnU6Mg==") {
        name
        menuItems {
          nodes {
            label
            id
            url
            parentId
            childItems {
              nodes {
                label
                url
                id
                parentId
              }
            }
          }
        }
      }
      altMenu: menu(id: "bmF2X21lbnU6Nw==") {
        name
        menuItems(first: 100, where: {parentId: "null"}) {
          nodes {
            label
            id
            childItems {
              nodes {
                label
                url
                id
                parentId
                childItems {
                  nodes {
                    label
                    url
                    id
                    parentId
                    childItems {
                      nodes {
                        label
                        url
                        id
                        parentId
                        childItems {
                          nodes {
                            label
                            url
                            id
                            parentId
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`