import React from "react";
import "./LandingPageContactSection.css";

const LandingPageContactSection = ({
  contactBackgroundImage,
  contactTitle,
  contactInfoParagraph,
  contactEmail,
  contactPhone,
  contactAddress,
  hidden,
}) => {
  if (hidden) return null;
  return (
    <div
      className="contactSectionContent"
      style={{ backgroundImage: `url(${contactBackgroundImage})` }}
      id="contact"
    >
      <div className="contactInfo">
        <h2 className="contactTitle">{contactTitle}</h2>
        <p className="contactInfoParagraph">{contactInfoParagraph}</p>
        <span>{contactEmail}</span> <br />
        <span>{contactPhone}</span> <br />
        <span>{contactAddress}</span> <br />
      </div>
    </div>
  );
};

export default LandingPageContactSection;
