import React from "react";
import "./LandingPageAboutSection.css";

const LandingPageAboutSection = ({
  aboutSectionTitle,
  aboutSection,
  aboutSectionLogo,
  aboutSectionImage,
}) => (
  <>
    <div className="aboutSection" id="about">
      <h2 className="aboutSectionTitle">{aboutSectionTitle}</h2>
      <p className="aboutSectionText">{aboutSection}</p>
      <img
        src={aboutSectionImage.sourceUrl}
        className="aboutSectionMainImage"
      />
      <img src={aboutSectionLogo.sourceUrl} className="aboutSectionLogo" />
    </div>
  </>
);

export default LandingPageAboutSection;
