import React from "react";
import { join } from "lodash";
import LandingPageSchemeButton from "../LandingPageSchemeButton/LandingPageSchemeButton.js";
import "./LandingPageJumbotron.css";

const TitleOnOneRow = ({ title }) => (
  <h1 className="titleOnOneRow">{join(title, "")}</h1>
);

const MobileViewPageTitle = ({ title }) => {
  const screenWidth = process.browser
    ? document.documentElement.clientWidth
    : 1200;
  if (screenWidth < 1115 && screenWidth >= 845) {
    let mobileViewTitle = "";
    for (let i = 0; i < title.length - 1; i++) {
      if (i > 0) {
        mobileViewTitle += title[i] + "-";
      } else mobileViewTitle += title[i];
    }
    return <h1>{mobileViewTitle + title[title.length - 1]}</h1>;
  } else if (screenWidth < 845) {
    let mobileViewTitle = "";
    for (let i = 0; i < title.length - 1; i++) {
      mobileViewTitle += title[i] + "-";
    }
    return <h1>{mobileViewTitle + title[title.length - 1]}</h1>;
  }
  return <h1>{title}</h1>;
};

const LandingPageJumbotron = ({
  backgroundImage,
  landingPageJumbotronTitleBeginning,
  landingPageJumbotronTitleEnding,
  schemeButton,
  schemeButtonUrl,
}) => (
  <div
    className="landingPageContent"
    style={{ backgroundImage: `url(${backgroundImage})` }}
  >
    <h1 className="titleBeginning">{landingPageJumbotronTitleBeginning}</h1>
    <TitleOnOneRow title={landingPageJumbotronTitleEnding} />
    <div className="mobilePageTitle invisible">
      <h1>{landingPageJumbotronTitleBeginning}</h1>
      <MobileViewPageTitle title={landingPageJumbotronTitleEnding} />
    </div>
    <LandingPageSchemeButton
      schemeButton={schemeButton}
      url={schemeButtonUrl}
    />
  </div>
);

export default LandingPageJumbotron;
